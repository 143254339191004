import React, { useEffect } from "react";

import Choose from "../components/home/choose";
import Inspiration from "../components/home/inspiration";
import Partners from "../components/home/partners";
import Serving from "../components/home/serving";
import Special from "../components/home/special";
import SupportedBy from "../components/home/supportedBy";

const Home = () => {
  // useEffect(() => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Authorization",
  //     "Basic " + window.btoa("ocdyv:Octacore@123")
  //   );
  //   fetch("http://4.193.89.139:5000/weather", {
  //     headers: myHeaders,
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw Error("Can not fetch data from that resource");
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // });
  return (
    <div className="bg-white">
      <Special />
      <Choose />
      <Serving />
      <Inspiration />
      <Partners />
      <SupportedBy />
    </div>
  );
};

export default Home;
