import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import { BASE_URL } from "../utils/axios/axios";
import useAxiosAuth from "../utils/axios/useAxiosAuth";

const TailoredMadeTrip = () => {
  const { data: destinationList } = useAxiosAuth(`Destination`);
  const { data: countryList } = useAxiosAuth(`Country`);

  const [arrivalDate, setArrivalDate] = useState("");
  const [duration, setDuration] = useState();
  const [destination, setDestination] = useState("");
  const [hotelCategory, setHotelCategory] = useState("");
  const [activity, setActivity] = useState("");
  const [noOfAdult, setNoOfAdult] = useState();
  const [noOfChildren, setNoOfChildren] = useState();

  const [salutation, setSalutation] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState();
  const [contactNumber, setContactNumber] = useState("");
  const [localAdddress, setLocalAdddress] = useState("");
  const [specialRequest, setSpecialRequest] = useState("");

  useEffect(() => {
    const arrivalDateInput = document.getElementById("arrivalDate");
    if (arrivalDateInput) {
      arrivalDateInput.addEventListener("focus", function () {
        this.type = "date";
      });
      arrivalDateInput.addEventListener("blur", function () {
        this.type = "text";
      });
    }
  }, []);

  const submitTailoredTrip = (event) => {
    event.preventDefault();
    const payload = {
      // Package Requirements
      arrivalDate: arrivalDate,
      days: duration,
      destinationId: destination,
      hotelCategory: hotelCategory,
      activity: activity,
      noOfAdults: noOfAdult,
      noOfChildren: noOfChildren,
      specialRequest: specialRequest,

      // Personal Details
      personalDetailsSalutation: salutation,
      personalDetailsFirstName: firstName,
      personalDetailsLastName: lastName,
      personalDetailsAddress: localAdddress,
      personalDetailsCountryId: nationality,
      personalDetailsEmail: email,
      personalDetailsPhoneNumber: contactNumber,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/api/TailorMadeTripRequest/`,
      headers: {
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        setArrivalDate("");
        setDuration("");
        setDestination("");
        setHotelCategory("");
        setActivity("");
        setNoOfAdult("");
        setNoOfChildren("");
        setSalutation("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setNationality("");
        // setCountryCode("");
        setContactNumber("");
        setLocalAdddress("");
        setSpecialRequest("");

        toast.success("Tailor Made Trip request recorded succesfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Tailor Made Trip request has failed!");
      });
  };

  return (
    <div className="xl:w-[90vw] px-5 mx-auto py-12">
      <ToastContainer />
      <div className="text-[#1D306D] mx-auto text-center py-10">
        <h1 className="text-[56px]">Tailor Made Trip</h1>
        <span className="text-[#8A94B2] text-[20px]">
          Customize your trip the way you like it
        </span>
      </div>
      <form
        action=""
        className="text-[#4B4F52] w-full"
        onSubmit={submitTailoredTrip}
      >
        {/* Package Requirement Section */}
        <div className="shadow-[0_0_8px_#f1f1f1] border rounded-lg p-5 mb-7">
          <span className="text-[18px] pb-10 block">Package Requirements</span>

          <div className="md:grid md:grid-cols-2 gap-8">
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                name="arrivalDate"
                id="arrivalDate"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={arrivalDate}
                onChange={(e) => {
                  setArrivalDate(e.target.value);
                }}
                required
              />
              <label
                htmlFor="arrivalDate"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Arrival Date *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="number"
                name="tripDuration"
                id="tripDuration"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                required
              />
              <label
                htmlFor="tripDuration"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Duration *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <select
                name="destination"
                id="destination"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                value={destination}
                onChange={(e) => {
                  setDestination(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select a Destination
                </option>
                {destinationList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="destination"
                className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Destination
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="hotelCategory"
                id="hotelCategory"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={hotelCategory}
                onChange={(e) => {
                  setHotelCategory(e.target.value);
                }}
                required
              />
              <label
                htmlFor="hotelCategory"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Hotel Category *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="activity"
                id="activity"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={activity}
                onChange={(e) => {
                  setActivity(e.target.value);
                }}
                required
              />
              <label
                htmlFor="activity"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Activity *
              </label>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="relative z-0 w-full mb-8 md:mb-5 group">
                <select
                  name="noOfAdult"
                  id="noOfAdult"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={noOfAdult}
                  onChange={(e) => {
                    setNoOfAdult(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfAdult"
                  className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Adult *
                </label>
              </div>
              <div className="relative z-0 w-full mb-8 md:mb-5 group">
                <select
                  name="noOfChildren"
                  id="noOfChildren"
                  className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md "
                  value={noOfChildren}
                  onChange={(e) => {
                    setNoOfChildren(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <label
                  htmlFor="noOfChildren"
                  className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
                >
                  No. of Children
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Personal Details  Section */}
        <div className="shadow-[0_0_8px_#f1f1f1] border rounded-lg p-5 mb-7">
          <span className="text-[18px]">
            Personal Details
            <br />
          </span>
          <br />
          <div className="grid grid-cols-2 gap-8">
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <select
                name="salutations"
                id="salutations"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                value={salutation}
                onChange={(e) => {
                  setSalutation(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                <option value="0">Mr.</option>
                <option value="1">Miss.</option>
                <option value="2">Mrs.</option>
              </select>
              <label
                htmlFor="salutations"
                className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Salutations *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="firstName"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                First Name *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                required
              />
              <label
                htmlFor="lastName"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Last Name *
              </label>
            </div>

            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="email"
                name="emailAddress"
                id="emailAddress"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <label
                htmlFor="emailAddress"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Email Address *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <select
                name="countrySelection"
                id="countrySelection"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                value={nationality}
                onChange={(e) => {
                  setNationality(e.target.value);
                }}
                required
              >
                <option value="" disabled selected>
                  Select
                </option>
                {countryList.map((item, index) => (
                  <option key={index} value={item.alpha3Code}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="countrySelection"
                className="peer-focus:font-medium absolute text-[14px] text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Nationality *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                name="countryCodeSelection"
                id="countryCodeSelection"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#edf1ff99] focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={nationality}
                required
                disabled
              />
              <label
                htmlFor="countryCodeSelection"
                className="peer-focus:font-medium absolute text-sm text-gray-400 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Country Code *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="number"
                name="contactNo"
                id="contactNo"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
                required
              />
              <label
                htmlFor="contactNo"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Contact No. *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group">
              <input
                type="text"
                name="localAddress"
                id="localAddress"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={localAdddress}
                onChange={(e) => {
                  setLocalAdddress(e.target.value);
                }}
                required
              />
              <label
                htmlFor="localAddress"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Local Address *
              </label>
            </div>
            <div className="relative z-0 w-full mb-8 md:mb-5 group col-span-2">
              <textarea
                type="textarea"
                name="specialRequest"
                id="specialRequest"
                className="block py-2.5 px-4 w-full text-[#8A94B2] bg-[#1D306D]/5 focus:outline-none focus:ring-0 peer rounded-md"
                placeholder=""
                value={specialRequest}
                onChange={(e) => {
                  setSpecialRequest(e.target.value);
                }}
              />
              <label
                htmlFor="specialRequest"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform translate-x-2 -translate-y-8 scale-85 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-85 peer-focus:-translate-y-8 peer-focus:translate-x-0"
              >
                Special Request
              </label>
            </div>
          </div>
        </div>

        <div className="w-[162px] mx-auto">
          <input
            type="submit"
            value="Request Now"
            className="w-full mx-auto bg-secondary text-white rounded-full py-4"
          />
        </div>
      </form>
    </div>
  );
};

export default TailoredMadeTrip;
