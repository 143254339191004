import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Slider from "react-slick";

import parntersSvg from "../../assets/images/partnersSvg.svg";
import { BASE_URL } from "../../utils/axios/axios";
import useAxiosAuth from "../../utils/axios/useAxiosAuth";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] 2xl:right-14 right-0 border-2 p-2 bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={`absolute z-50 top-[40%] 2xl:left-14 left-0 border-2 p-2  bg-secondary text-4xl text-white rounded-2xl cursor-pointer`}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <MdChevronLeft />
    </div>
  );
}
const Partners = () => {
  const { data: images } = useAxiosAuth(`Partner`);
  const filteredImages = images.filter((item) => item.published);
  const onlyPartners = filteredImages.filter((item) => item.type === 2);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1315,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // console.log(onlyPartners);

  return (
    <div className="pt-[10vh]">
      <div className="text-center">
        <h1 className="md:text-5xl text-3xl text-[#1D306D] pb-2">
          Our Partners
        </h1>
        <p className="text-[#8A94B2]">
          Partnering with top travel experts to create unforgettable experiences
          worldwide.
        </p>
      </div>
      <div className="h-[38vh]">
        <img
          className="absolute object-cover w-full md:h-[40vh] h-[30vh] border-b border-primary2"
          src={parntersSvg}
          alt="bg design"
        />
        <div className="px-10 pt-20 h-[35vh]">
          {onlyPartners.length > 6 ? (
            // <div className="flex flex-row md:gap-10 gap-3 z-[1] relative">
            <Slider {...settings}>
              {onlyPartners.map((image, index) => (
                <img
                  key={index}
                  className="md:w-[12vw] w-[16vw] md:max-h-[26vh] max-h-[20vh] object-contain px-10"
                  src={image.image && `${BASE_URL}/${image.image.filePath}`}
                  alt={image.image ? image.image.altInfo : "Image unavailable"}
                />
              ))}
            </Slider>
          ) : (
            // </div>
            <div className="flex justify-center">
              {onlyPartners.map((image, index) => (
                <img
                  key={index}
                  className="md:max-w-[22vw] max-w-[16vw] md:max-h-[26vh] max-h-[20vh] object-contain px-10 z-10 relative"
                  src={image.image && `${BASE_URL}/${image.image.filePath}`}
                  alt={image.image ? image.image.altInfo : "Image unavailable"}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Partners;
