import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import { GiCrossedBones } from "react-icons/gi";

import { axios } from "../../utils/axios/axios";
import "./playground.css";

const Playground = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isAiTyping, setIsAiTyping] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messages.length === 0) {
      axios
        .get("api/core/chat", {
          params: {
            message: "greetings",
          },
        })
        .then((response) => {
          setMessages((prev) => [
            ...prev,
            {
              sender: "assistant",
              content: response.data.data.response,
            },
          ]);
        })
        .finally(() => {
          setIsAiTyping(false);
        });
    }
  }, [messages]);

  const sendMessage = (event) => {
    event.preventDefault();
    setIsAiTyping(true);
    setMessages((prev) => [
      ...prev,
      {
        sender: "user",
        content: userMessage,
      },
    ]);
    const message = userMessage;
    setUserMessage("");

    const last4Messages = messages.slice(-4);

    axios
      .post("api/core/chat", {
        message,
        chatMessageLogs: last4Messages.map((message) => ({
          text: message.content,
          role: message.sender,
        })),
      })
      .then((response) => {
        setMessages((prev) => [
          ...prev,
          {
            sender: "assistant",
            content: response.data.data.response,
          },
        ]);
      })
      .finally(() => {
        setIsAiTyping(false);
      });
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isAiTyping]);

  return (
    <div className="relative z-50">
      <div
        className={`chat bg-white border-primary border fixed -bottom-40 -right-32 ${open ? "visible" : "invisible"}`}
      >
        <div className="chat-title relative">
          <h1>Druk Air Holidays</h1>
          <h2>Bhutan</h2>
          <figure className="avatar">
            <img
              alt="display"
              src={require("../../assets/images/bhutan1.png")}
            />
          </figure>
          <GiCrossedBones
            className="absolute top-4 right-3 cursor-pointer"
            onClick={(e) => setOpen(!open)}
          />
        </div>
        <div className="messages">
          <div className="messages-content px-4 overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-transparent">
            {/* Recieve message */}
            {messages.map((message) => {
              return message.sender === "assistant" ? (
                <div className="message new">
                  <figure className="avatar">
                    <img
                      alt="display"
                      src={require("../../assets/images/bhutan1.png")}
                    />
                  </figure>
                  {message.content}
                </div>
              ) : (
                <div className="message message-personal">
                  {message.content}
                </div>
              );
            })}
            <div className="message invisible" ref={messagesEndRef} />
            {/* Loading message */}
            {isAiTyping && (
              <div className="message loading new">
                <figure className="avatar">
                  <img
                    alt="display"
                    src={require("../../assets/images/bhutan1.png")}
                  />
                </figure>
                <span></span>
              </div>
            )}
          </div>
        </div>
        <form onSubmit={sendMessage} className="flex border-t border-primary">
          <div className="relative w-full">
            <input
              type="text"
              id="message-input"
              className="block p-2.5 w-full z-20 text-black text-sm bg-gray-50 focus-visible:outline-none "
              placeholder="Type message..."
              value={userMessage}
              onChange={(e) => {
                setUserMessage(e.target.value);
              }}
              required
            />
          </div>
          <button type="submit" className="p-2.5 text-sm bg-secondary group">
            <FaArrowRight className="text-white group-hover:scale-125 transition ease-in-out" />
          </button>
        </form>
      </div>

      <div
        className="fixed text-primary bottom-5 right-5 z-[99] bg-slate-100 opacity-90 hover:opacity-100 transition ease-in-out shadow-md border border-primary rounded-full p-3 hover:scale-105 cursor-pointer"
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        <AiOutlineMessage className="lg:h-[2vw] lg:w-[2vw] h-8 w-8" />
      </div>
    </div>
  );
};

export default Playground;
